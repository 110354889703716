import { Routes,Route } from "react-router-dom";
import Layout from "./components/Layout"
import SolarAd from "./pages/SolarAd"
import SolarQuestionary from "./pages/SolarQuestionary/SolarQuestionary"

// import Missing from "./missing/Missing"

import { createTheme, ThemeProvider } from '@mui/material/styles';

function App() {

  const theme = createTheme({
    typography: {
      fontFamily: ["Barlow Condensed"].join(","),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<SolarAd />} />
          <Route path="solar" element={<SolarAd />} />
          <Route path="compendium" element={<SolarQuestionary />} />

          {/* catch all */}
          {/* <Route path="*" elememt={<Missing />} /> */}

        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;