import { useRef, useState, useEffect } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {Link} from 'react-router-dom';

import axios from "../../api/axios";
import "./SolarQuestionary.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //width: 700,
  //height: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  margin: "0px",
  p: 2,
};

const SolarQuestionary = () => {
  const [params, setParams] = useState({
    form: "compendium",
    name: "",
    email: "",
    phone: "",
    country: "",
    housetype: "",
    query1: "",
    query2: "",
    query3: "",
    query4: "",
    query5: "",
    comment: "",
  });
  const [submitted, setSubmitted] = useState(false);
  //const [error, setError] = useState("");

  const validate = () => {
    return (
      (params.email || params.phone) &&
      params.name &&
      params.country &&
      params.housetype &&
      params.query1 &&
      params.query2 &&
      params.query3 &&
      params.query4 &&
      params.query5
    );
  };

  const handleChange = (event) => {
    const target = event.currentTarget;
    setParams({
      ...params,
      [target.name]: target.type === "checkbox" ? target.checked : target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("email/send", params, {
        headers: {
          token: process.env.REACT_APP_API_TOKEN,
        },
      });
    } catch (err) {
      console.log(err);
    }
    setSubmitted(true);
  };

  const renderSubmitReply = () => (
    <>
      <div style={{ marginBottom: "20px" }}>
        Tack för att du tog kontakt, informationskompendiet kan nu laddas ner
      </div>

      <Link
        to={process.env.REACT_APP_SOLAR_COMPENDIUM_URL}
        download
        target="_blank"
        rel="noreferrer"
      >
      {/* <Button variant="contained" onClick={downloadFile}>Ladda ner</Button> */}
      <Button variant="contained">Ladda ner</Button>
      </Link>
    </>
  );

  // const downloadFile = async () => {
  //   axios({
  //     url: '/download/compendium', //your url
  //     method: 'GET',
  //     headers: {
  //       token: process.env.REACT_APP_API_TOKEN,
  //     },
  //     responseType: 'blob', // important
  // }).then((response) => {
  //     // create file link in browser's memory
  //     const href = URL.createObjectURL(response.data);
  
  //     // create "a" HTML element with href to file & click
  //     const link = document.createElement('a');
  //     link.href = href;
  //     link.setAttribute('download', 'file.pdf'); //or any other extension
  //     document.body.appendChild(link);
  //     link.click();
  
  //     // clean up "a" element & remove ObjectURL
  //     document.body.removeChild(link);
  //     URL.revokeObjectURL(href);
  // });
  // };

  return (
    <div className="solar">
    <Box sx={style}>
      <div className="container">
        {submitted ? (
          renderSubmitReply()
        ) : (
          <>
            <form className="container" onSubmit={handleSubmit}>
              <h3 className="header">
                Vänligen besvara några frågor innan du laddar ner vårt
                informationskompendie om Solceller
              </h3>

              <div className="columns">
                <div className="leftCol">
                  <div className="dataField">
                    <label className="dataFieldLabel">
                      Jag är rätt säker på att jag kommer investera i en
                      solcellsanläggning inom snar framtid:
                    </label>
                    <FormControl size="small">
                      <FormControl>
                        <RadioGroup
                          name="query1"
                          onChange={handleChange}
                          value={params.query1}
                        >
                          <FormControlLabel
                            value="Ja"
                            control={<Radio className="radio" />}
                            label="Ja"
                          />
                          <FormControlLabel
                            value="Nej"
                            control={<Radio className="radio" />}
                            label="Nej"
                          />
                        </RadioGroup>
                      </FormControl>
                    </FormControl>
                  </div>
                  <Divider />
                  <div className="dataField">
                    <label className="dataFieldLabel">
                      Jag funderar på att investera i en solcellsanläggning:
                    </label>
                    <FormControl size="small">
                      <FormControl>
                        <RadioGroup
                          name="query2"
                          onChange={handleChange}
                          value={params.query2}
                        >
                          <FormControlLabel
                            value="Ja"
                            control={<Radio className="radio" />}
                            label="Ja"
                          />
                          <FormControlLabel
                            value="Nej"
                            control={<Radio className="radio" />}
                            label="Nej"
                          />
                        </RadioGroup>
                      </FormControl>
                    </FormControl>
                  </div>
                  <Divider />
                  <div className="dataField">
                    <label className="dataFieldLabel">
                      Jag tror solceller kanske kan vara något för mig
                    </label>
                    <FormControl size="small">
                      <FormControl>
                        <RadioGroup
                          name="query3"
                          onChange={handleChange}
                          value={params.query3}
                        >
                          <FormControlLabel
                            value="Ja"
                            control={<Radio className="radio" />}
                            label="Ja"
                          />
                          <FormControlLabel
                            value="Nej"
                            control={<Radio className="radio" />}
                            label="Nej"
                          />
                        </RadioGroup>
                      </FormControl>
                    </FormControl>
                  </div>
                  <Divider />
                  <div className="dataField">
                    <label className="dataFieldLabel">
                      Jag söker mer information om solceller
                    </label>
                    <FormControl size="small">
                      <FormControl>
                        <RadioGroup
                          name="query4"
                          onChange={handleChange}
                          value={params.query4}
                        >
                          <FormControlLabel
                            value="Ja"
                            control={<Radio className="radio" />}
                            label="Ja"
                          />
                          <FormControlLabel
                            value="Nej"
                            control={<Radio className="radio" />}
                            label="Nej"
                          />
                        </RadioGroup>
                      </FormControl>
                    </FormControl>
                  </div>
                  <Divider />
                  <div className="dataField">
                    <label className="dataFieldLabel">
                      Jag är intresserad av solceller till en annan fastighet än
                      där jag bor
                    </label>
                    <FormControl size="small">
                      <FormControl>
                        <RadioGroup
                          name="query5"
                          onChange={handleChange}
                          value={params.query5}
                        >
                          <FormControlLabel
                            value="Ja"
                            control={<Radio className="radio" />}
                            label="Ja"
                          />
                          <FormControlLabel
                            value="Nej"
                            control={<Radio className="radio" />}
                            label="Nej"
                          />
                        </RadioGroup>
                      </FormControl>
                    </FormControl>
                  </div>
                </div>

                <div className="rightCol">
                  <div className="dataField">
                    <label className="dataFieldLabelRight">Namn:</label>
                    <TextField
                      name="name"
                      size="small"
                      margin="dense"
                      fullWidth
                      value={params.name}
                      onChange={handleChange}
                    />
                  </div>
                  <Divider />
                  <div className="dataField">
                    <label className="dataFieldLabelRight">E-post:</label>
                    <TextField
                      name="email"
                      size="small"
                      margin="dense"
                      fullWidth
                      value={params.email}
                      onChange={handleChange}
                    />
                  </div>
                  <Divider />
                  <div className="dataField">
                    <label className="dataFieldLabelRight">Telefon:</label>
                    <TextField
                      name="phone"
                      size="small"
                      margin="dense"
                      fullWidth
                      value={params.phone}
                      onChange={handleChange}
                    />
                  </div>
                  <Divider />

                  <div className="dataField">
                    <label className="dataFieldLabelRight">Jag bor:</label>
                    <FormControl size="small">
                      <FormControl>
                        <RadioGroup
                          size="large"
                          name="country"
                          onChange={handleChange}
                          value={params.country}
                          >
                          <FormControlLabel
                            value={"På Åland"}
                            control={<Radio className="radio" />}
                            label="På Åland"
                          />
                          <FormControlLabel
                            value={"Fasta Finland"}
                            control={<Radio className="radio" />}
                            label="Fasta Finland"
                          />
                          <FormControlLabel
                            value={"Sverige"}
                            control={<Radio className="radio" />}
                            label="Sverige"
                          />
                        </RadioGroup>
                      </FormControl>
                    </FormControl>
                  </div>
                  <Divider />

                  <div className="dataField">
                    <label className="dataFieldLabelRight">Jag bor i:</label>
                    <FormControl size="small">
                      <FormControl>
                        <RadioGroup
                          name="housetype"
                          onChange={handleChange}
                          value={params.housetype}
                        >
                          <FormControlLabel
                            value="Lägenhet"
                            control={<Radio className="radio" />}
                            label="Lägenhet"
                          />
                          <FormControlLabel
                            value="Egnahemshus"
                            control={<Radio className="radio" />}
                            label="Egnahemshus"
                          />
                        </RadioGroup>
                      </FormControl>
                    </FormControl>
                  </div>
                </div>
              </div>

              <div className="textfield">
                <TextField
                  name="comment"
                  placeholder="Övriga frågor"
                  multiline
                  fullWidth
                  rows={2}
                  value={params.comment}
                  onChange={handleChange}
                />
              </div>

              <Button variant="contained" disabled={!validate()} type="submit">
                Skicka
              </Button>
            </form>
          </>
        )}
      </div>
    </Box>
    </div>
  );
};

export default SolarQuestionary;
