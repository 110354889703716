import { useRef, useState, useEffect } from 'react'
// import { Container, Card, Spacer, Button, Dropdown, Text, Textarea, Input, Modal, Radio } from "@nextui-org/react";
import Modal from '@mui/material/Modal';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import validator from "validator";

import axios from '../api/axios'
import './SolarQuote.scss'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  //width: 700,
  //height: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  margin: '0px',
  p: 4,
};

 {/* (falsad plåt, tegel, filt/korrugerad plåt/immiterat tegel (plåt) */}
 
const materialItems = [
  "Tegel",
  "Immiterat tegel (plåt)",
  "Falsad plåt", 
  "Korrugerad plåt",
  "Filt"
];
 
const angleItems = [
  "<20 grader",
  "20-27 grader",
  ">27 grader"
]

const directionItems = [
  "Syd",
  "Sydväst",
  "Sydöst",
  "Öst",
  "Väst"
];


const SolarQuote = ({visible, setVisible}) => {
  const [material, setMaterial] = useState("");
  const [angle, setAngle] = useState("");
  const [direction, setDirection] = useState("");
  const [installation, setInstallation] = useState("0");
  const [consumption, setConsumption] = useState("");
  const [panelAmount, setPanelAmount] = useState("");
  const [inverterPower, setInverterPower] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState("");
  const [submitted, setSubmitted] = useState(false);
  //const [error, setError] = useState("");

  const validate = () => {
    return installation === "0" 
      ? material && angle && direction && name && (email && validator.isEmail(email) || phone) && consumption
      : material && angle && direction && name && (email && validator.isEmail(email) || phone) && panelAmount && inverterPower
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      //const data = new FormData(e.target)
      //let formObject = Object.fromEntries(data.entries())
      const params = {
        name,
        address,
        email,
        phone,
        comment,
        material,
        angle,
        direction,
        installation,
        consumption,
        panelAmount,
        inverterPower
      };

      await axios.post("email/send", params, {
        headers: {
          'token': process.env.REACT_APP_API_TOKEN
        }
      })
      setSubmitted(true)
    }
    catch(err) {
      console.log(err)
    }

    setSubmitted(true)

  }

  return (
    <Modal
      open={visible}
      onClose={() => setVisible(false)}
    >
    <Box sx={style}>
      <main className="container">
        {submitted
          ? (
            <>
              <div style={{marginBottom: '20px'}} >Tack för att du tog kontakt, vi återkommer så snart vi behandlat din förfrågan</div>
              <Button
                variant="contained"
                onClick={() => setVisible(false)}
              >
                Stäng
              </Button>
            </>
          )
          : (
        <>
        <form className="container" onSubmit={handleSubmit}>
        <h3 className="header">Vänligen ange nedanstående så att vi kan ge en så exakt offert som möjligt</h3>
        <div className="columns">
          <div className="left">
            <div className="dataInput">
              <label>Typ av takbeklädnad:</label>
              <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                <Select
                  name="material"
                  displayEmpty
                  value={material}
                  onChange={(e) => setMaterial(e.target.value)}
                >
                  <MenuItem value=""><em>Välj material</em></MenuItem>
                  {materialItems.map((value, key) => (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="dataInput">
              <label>Takets lutning:</label>
              <FormControl sx={{ m: 1, minWidth: 150 }} size="small" >
                <Select
                  name="angle"
                  displayEmpty
                  value={angle}
                  onChange={(e) => setAngle(e.target.value)}
                >
                  <MenuItem value=""><em>Välj lutning</em></MenuItem>
                  {angleItems.map((value, key) => (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="dataInput">
              <label>Väderstreck vart takytorna vätter:</label>
              <FormControl sx={{ m: 1, minWidth: 150 }} size="small" >
                <Select
                  name="direction"
                  displayEmpty
                  value={direction}
                  onChange={(e) => setDirection(e.target.value)}
                >
                  <MenuItem value=""><em>Välj väderstreck</em></MenuItem>
                  {directionItems.map((value, key) => (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div style={{padding: "0x 0px"}} >Önskar du</div>
              <div className="dataInput">
              <RadioGroup
                name="installation"
                row
                onChange={(e) => setInstallation(e.target.value)}
                value={installation}
              >
                <FormControlLabel 
                  value={0}
                  control={<Radio />} 
                  label="Förslag på installation" 
                />
                <FormControlLabel 
                  value={1}
                  control={<Radio />} 
                  label="Dimensionera själv" 
                />
              </RadioGroup>
            </div>

            {installation === "0" && (
              <div className="dataInput">
                <label>Årlig elkonsumtion:</label>
                <TextField
                  name="consumption"
                  size="small"
                  margin="dense" 
                  style = {{width: 80}}
                  value={consumption}
                  onChange={(e) => setConsumption(e.target.value)}
                />
                <span style={{paddingLeft:"5px"}}>kWh</span>
              </div>
            )}

            {installation === "1" && (
              <>
                <div className="dataInput">
                  <label>Antal paneler:</label>
                  <TextField
                    name="panelAmount"
                    size="small"
                    margin="dense" 
                    style = {{width: 80}}
                    value={panelAmount}
                    onChange={(e) => setPanelAmount(e.target.value)}
                  />
                  <span style={{paddingLeft:"5px"}}>st</span>
                </div>
                <div className="dataInput">
                  <label>Effekt på växelriktare:</label>
                  <TextField
                    name="inverterPower"
                    size="small"
                    margin="dense" 
                    style = {{width: 80}}
                    value={inverterPower}
                    onChange={(e) => setInverterPower(e.target.value)}
                  />
                  <span style={{paddingLeft:"5px"}}>kW</span>
                </div>    
              </>
            )}
          </div>

          <div className="right">
            <div className="dataInput">
              <TextField
                name="name"
                label="Namn"
                size="small"
                margin="dense" 
                fullWidth 
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="dataInput">
              <TextField
                name="address"
                label="Adress"
                size="small"
                margin="dense" 
                fullWidth 
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="dataInput">
              <TextField
                name="email"
                label="E-post"
                size="small"
                margin="dense" 
                fullWidth 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="dataInput">
              <TextField
                name="phone"
                label="Telefon"
                size="small"
                margin="dense"
                fullWidth 
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

          </div>

        </div>

        <div className="textfield">
          <TextField
            name="comment"
            placeholder="Övriga frågor"
            multiline
            fullWidth
            rows={2}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            />
        </div>

        <Button
          variant="contained"
          disabled={!validate()}
          type="submit"
        >
          Skicka offertförfrågan
        </Button>
        </form>
        </>
      )}

      </main> 
    </Box>
  </Modal>
  )

}

export default SolarQuote