import { useState } from "react"
import Button from '@mui/material/Button'
import SolarQuote from '../components/SolarQuote'
import './SolarAd.scss'

const SolarAd = () => {
	const [visible, setVisible] = useState(false);

  return (
    <main className="main">
      <div className="wrapper">

        <h2 className="header">Skaffa Solenergi idag!</h2>

        <div className="topLeftItems">
          <ul>
            <li><img src="thumbsdown.png" className="image" />Elprisen är höga</li>
            <li><img src="thumbsup.png" className="image" />Solen finns som en gratis energikälla för alla</li>
          </ul>
        </div>

        <div className="buttonContainer">
          <div className="info">

            Är du intresserad att få ett kostnadsförslag? 
            Klicka här så samlar vi in den information vi behöver för att hjälpa dig
          </div>
          <div>
          <Button 
            onClick={setVisible}
            variant="contained"
            size="large"
          >
            Offertförfrågan
          </Button>
          </div>
        </div>

        <div className="bottomLeftItems">
          <ul>
            <li>Vi erbjuder marknadsledande produkter och proffsiga installationer till konkurrenskraftiga priser!</li>
            <li>Vi är Ålands största företag inom energiteknik och experter på solenergi!</li>
            <li>Kontakta oss för ett kostnadsfritt förslag specifikt för din fastighet!</li>
          </ul>
        </div>

        <div className="bottomRightItems">
          <div className="contact">
            info@acs.ax<br />
            +358 18 22 111<br /><br />
            www.acs.ax
          </div>
          <div className="image">
            <img
              width={200}
              src="/logo.png"
              alt="Default Image"
            />
          </div>
        </div>

        <div className="bottomRightCircle"></div>

        {visible && <SolarQuote visible={visible} setVisible={setVisible} /> }

      </div>
    </main>
  )
}

export default SolarAd